:root { 
    --border: #cccccc;
  }
.hvss-header{
    padding: 16px;
    border-bottom: 1px solid var(--border);
    position: sticky;
    top: 0;
    z-index: 9999;
    background-color: #ffffff;
}