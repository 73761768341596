:root { 
    --border: #cccccc;
  }

.search-data{
    max-width: 1100px;

    .table-wrapper{
        padding: 40px 20px;
    }
    .table-search{ 
        input{
            max-width: 100%;
            height: 60px;
        }
    }
    table{
        border: 1px solid var(--border);
        min-width: 600px;
        tr{
            td{
                padding: 19px 15px;
            }
        }
    }
}