.side-menu {
    background-color: #147594;
    max-width: 300px;
    width: 100%;
    min-height: 100vh;
    padding: 40px 8px; 
    z-index: 9999;
    transition: all ease-in-out 0.3s;
    position: fixed;
    .hvss-menu{
        a{
            padding: 16px;
            border-radius: 4px;
            margin-bottom: 8px;
            &:hover{
                background-color: rgb(20, 151, 194, 0.5);
            }
            &.active{
                background-color: #1497C2;
            }
        }
        .submenu {
            list-style: none;
            padding-left: 0px;
            margin: 10px 0 0 0;
            width: 100%;
            a {
                text-align: left;
                position: relative;
                padding-left: 40px;
                display: flex;
                align-items: center;
            }
        }
        
        .submenu-item {
            padding: 10px;
            color: #fff;
            text-decoration: none;
            display: block;
        }
    }
}