:root {
    --primary: #147594;
    --white: #ffffff;
    --black: #121212;
    --hover: #1497c2;
    --border: #cccccc;
  }

.loader{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0.3);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    .loading{ 
        border: 2px solid var(--border); /* Light grey */
        border-top: 2px solid var(--primary); /* Blue */
        border-radius: 50%;
        width: 60px;
        height: 60px;
        animation: spin 2s linear infinite; 
        margin: auto; 
    }
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


  
.inner-loader{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255,255,255,0.7);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  .loading{ 
      border: 2px solid var(--border); /* Light grey */
      border-top: 2px solid var(--primary); /* Blue */
      border-radius: 50%;
      width: 60px;
      height: 60px;
      animation: spin 2s linear infinite; 
      margin: auto; 
  }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}