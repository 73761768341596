:root {
    --primary: #147594;
    --white: #ffffff;
    --black: #121212;
    --hover: #1497c2;
    --border: #cccccc;
  }

.file-upload-box{
    border: 1px solid var(--border);
    border-radius: 4px;
    padding: 4px;
    min-height: 320px;
    background-color: rgb(20, 117, 148, 0.1);
    cursor: pointer;
    .input-box {
        border: 1px dashed var(--primary);
        border-radius: 4px;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        height: 312px;
        text-align: center;
        input{
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
        .file-information{
            // position: relative;
            z-index: 99;
            .regular-title{
                font-size: 19px;
                margin: 8px 0 0 0;
            }
        }
    }
}

.column-names{
    .regular-title{
        max-width: 200px;
        width: 100%;
    }    
    .select-column{
        max-width: 400px;
        width: 100%;
        select{
            appearance: none;
            background-color: transparent;    
            z-index: 1;
            position: relative;
            cursor: pointer;
            height: 44px;
        }
    }
}
.rule-input{
    max-width: 100px;
    width: 100%;
    input{
        height: 44px;
    }
}
.extract{
    td{
        padding: 8px 15px !important;
    }
}