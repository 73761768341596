:root {
    --primary: #147594;
    --white: #ffffff;
    --black: #121212;
    --hover: #1497c2;
    --border: #cccccc;
  }
*{
    box-sizing: border-box;
}
a{
    text-decoration: none !important;
}
body{
    overflow-x: hidden; 
    font-family: 'Public Sans', sans-serif !important;
    letter-spacing: 0.5px !important;
}
.c-pointer{
    cursor: pointer;
}
input,select{
    border: 1px solid #b5b5b5;
    width: 100%;
    height: 54px;
    border-radius: 4px;
    padding: 7px 15px;
}
button{
    &.primary-button{
        border: 1px solid var(--primary);
        background-color: var(--primary);
        font-size: 14px;
        font-weight: 400;
        border-radius: 4px;
        padding: 7px 15px;
        color: var(--white);
        min-width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 46px;
    }
    &.primary-button:disabled {
        opacity: 0.5; /* Example styling for a disabled button */
        cursor: not-allowed; /* Example styling for a disabled button */
      }
    &.transparent-button{
        background-color: transparent !important;
        border: none !important;
        color: var(--black) !important;
    }
    &.outline-button{
        background-color: transparent !important;
        border: 1px solid var(--border) !important;
        color: #121212 !important;
    }
}
.primary-button:hover{
    background-color: var(--hover);
    border: 1px solid var(--hover);
}

.login-wrapper{
    width: 100%;
    min-height: 100vh;
    padding: 20px 10px;
    background-color: var(--primary);
    .primary-title{
        color: var(--primary);
    }
    .white-box{
        background-color:  var(--white);
        padding: 40px 20px;
        max-width: 600px;
        width: 100%;
        margin: auto;
        border-radius: 8px;
        box-shadow: 0px 4px 15px 0px rgba(0,0,0,0.2);
    }
}
.primary-title{
    font-size: 42px;
    line-height: normal;
    margin: 0px 0px 35px;
    font-weight: 600;
    letter-spacing: 0px;
}
.secondary-title{
    font-size: 32px;
    font-weight: 500;
    margin: 0px 0px 24px;
    line-height: normal;
    color: var(--black);
    letter-spacing: 0px;
}
.page-title{
    font-size: 28px;
    font-weight: 400;
    line-height: normal; 
    color: var(--black);
    letter-spacing: 0px; 
}
.regular-title{
    font-size: 14px;
    font-weight: 400;
    line-height: normal; 
    color: var(--black);
    letter-spacing: 0px;
    .highlight{
        color: var(--primary);
    }
    &.bold-title{
        font-weight: 600;
    }
}
.label-title{
    font-size: 12px;
    font-weight: 400;
    line-height: normal; 
    color: var(--black);
    letter-spacing: 0px;
    text-align: left;
} 
.ico_float{
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &.right{    
        right: 15px;
    }
    &.left{
        left: 15px;
    }
}


.page-wrapper{
    max-width: calc(100% - 300px);
    width: 100%;
    text-align: left;
    margin-left: 300px;
}
.common-layout{
    padding: 34px 29px 20px 36px;
}
// table CSS
.table-wrapper{
    border: 1px solid var(--border);
    border-radius: 8px;
    .table-search{
        padding: 16px;
        .ico_float{
            width: 16px;
            height: 16px;
        }
        input{ 
            max-width: 360px;
            width: 100%;
            height: 36px;
            padding: 7px 10px 7px 35px;
            border: 1px solid var(--border);
        }
    }
    table {
        width: 100%;
        border-top: 1px solid var(--border);
        min-width: 700px;
        margin-bottom: 0px;
        background-color: #ffffff;
        border-radius: 8px;
        tr{
            &:last-child td{
                border-bottom: none;
            }
        th{
            // background-color: #FAFAFC;
            // padding: 19px 15px;
        }
        th,td{
            font-size: 14px;
            line-height: 22px;
            color: var(--black);
            // font-weight: 400; 
            vertical-align: middle;
        }
        td{
            // border-right: 1px solid var(--border);
            // border-bottom: 1px solid var(--border);
            background-color: transparent;
            &:last-child{
                border-right: none;
                padding: 8px 15px;
            }
            .dropdown-toggle{ 
                &::after{
                   display: none;
                } 
            }
            input{
                border: none !important;
                border-bottom: 1px solid transparent !important;
                outline: none;
                border-radius: 0px;
                height: 55px;
                margin-bottom: -1px;
                background-color: transparent;
                &:active,&:focus-visible{
                    border-bottom: 1px solid var(--primary) !important;
                }
            }
        }
        }
    }
}

// Dropdown
.dropdown-menu{
    border: 1px solid var(--border) !important;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1) !important;
    padding: 0 !important;
    border-radius: 4px !important;
    a{
        font-size: 15px !important;
        line-height: 22px !important;
        color: #121212 !important;
        font-weight: 400 !important;
        border-radius: 4px !important;
        padding: 9px 14px !important;
        &:hover{
            background-color: var(--hover) !important;
            color: var(--white) !important;
        }
    }
}

.dropdown-toggle{
    display: flex !important;
    align-items: center;
    &::after{
        border: none !important;
        background-image: url('../images/dropdown-arrow.svg') !important;
        width: 16px;
        height: 10px;
        background-repeat: no-repeat;
        background-position: center;
        margin-left: 10px !important;
    } 
}

.error-message{
    color: red; 
    text-align: left;
    font-size: 11px;
    margin-top: 4px;
}
.new-record{
    font-size: 14px;
    line-height: normal;
    color: var(--primary);
    font-weight: 500;
    cursor: pointer;
}
.back{
    .regular-title{
        color: #9d9d9d;
    }
}


@media (max-width: 991px){
    .side-menu{
        position: fixed;
        left: -100%;
    }
    .page-wrapper{
        max-width: 100%;
    }
    .common-layout {
        padding: 34px 16px 20px 16px;
    }
}
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination li a {
    display: inline-block;
    margin: 0 5px;
    padding: 5px 10px;
    border: 1px solid var(--border);
    border-radius: 4px;
    cursor: pointer;
    color: var(--black);
  }
  
  .pagination li.active a{
    background-color: var(--primary);
    color: #fff;
  }
  
  .pagination li:hover a{
    background-color: var(--hover);
    color: #fff;
  }

.edit-subcode{
    .dropdown-toggle {
        background-color: var(--primary) !important;
        border: 1px solid var(--primary) !important;
    }
}

.dropdown-item.active {
    background-color: var(--primary) !important;
    border: 1px solid var(--primary) !important;
}

.action-buttons {
    display: flex;
    justify-content: space-between;
}

.modal-backdrop,.modal {
    z-index: 9999 !important;
}

.table-container {
    position: relative;
}

.cancel-button-container {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -24px; /* Adjust as needed to align with the top of the table */
    margin-right: -24px; /* Adjust as needed to align with the right side of the table */
}

.offcanvas-backdrop{
    z-index: 9999 !important;
}
.offcanvas{
    z-index: 99999 !important;
    .table-wrapper{ table{
        border-top: none;
        min-width: 100%;
        td{
            padding: 7px 10px !important;
        }
    }}
}
.table-wrapper{
    &.extract{
        border: none;
        .table-search{
            border: 1px solid var(--border);
            border-bottom: none;
            border-radius: 8px 8px 0 0; 
        }
    }
    .extract-table{
        table{
            border: 1px solid var(--border);
            border-radius: 0 0 8px 8px; 
            tr{
                &:last-child{
                    td{
                        border-bottom: 1px solid var(--border);
                        border-radius: 0 0 8px 8px; 
                    }
                }
            }
        }
        .outline-button{
            padding: 10px 30px 10px 30px !important;
        }
    }
}
.vertical-divider{
    border: 1px solid var(--border);
    display: flex; 
    padding: 5px 10px;
    border-left: 0px;
}
.offcanvas {
    width: 40% !important;
}
.selected-table {
    border: 3px solid green !important; 
}
.swal2-container .swal2-confirm{
    background-color: var(--primary) !important;
}
.extract-format{
    background: white;
    position: sticky;
    top: 68px;
    z-index: 9;
    padding: 5px 1px 5px;
}
.file-status{
    color: red;
}
.back-button{
    .back-link{
        color: #9d9d9d;
        line-height: normal;
    }
}
.react-time-picker {
    background-color: transparent !important;
    color: #121212 !important;
    width: 100%;
}

.react-time-picker__wrapper {
    border: 1px solid var(--border) !important;
    padding: 5px 15px;
    border-radius: 5px;
}

.website-input-container{
    border: 1px solid var(--border) !important;
    padding: .5em;
    border-radius: 3px;
    margin-top: 1em;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .5em;
}

.website-item{
    background-color: rgb(218, 216, 216);
    display: inline-block;
    padding: .5em .75em;
    border-radius: 20px;
}
.website-item .close{
    height: 20px;
    width: 20px;
    background-color: rgb(48, 48, 48);
    color: #fff;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: .5em;
    font-size: 18px;
    cursor: pointer;
}

.website-input{
    flex-grow: 1;
    padding: .5em 0;
    border: none;
    outline: none;
}

.product-click{
    cursor: pointer;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #ffffff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    position: absolute;
    left: 80%;
    transform: translateX(-50%);
  }

  .download-sample{
    margin-top: 15px;
  }

  .download-link {
    color: #007bff;
    text-decoration: none;
    font-size: 0.875rem;
  }
  
  .download-link:hover {
    text-decoration: underline;
  }

  .doc-upload-box{
    border: 1px solid var(--border);
    border-radius: 4px;
    padding: 4px;
    background-color: rgb(20, 117, 148, 0.1);
    cursor: pointer;
    .doc-box {
        border: 1px dashed var(--primary);
        border-radius: 4px;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        text-align: center;
        input{
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
        .doc-information{
            // position: relative;
            z-index: 99;
            .regular-title{
                font-size: 19px;
                margin: 8px 0 0 0;
            }
        }
    }
}

.view-button{
    cursor: pointer;
}

.product-grid {
    display: flex;
    // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    flex-wrap: wrap;
    padding: 20px;
}
  
// .product-flex {
// display: flex;
// flex-wrap: wrap;
// gap: 20px;
// padding: 20px;
// }

.product-card {
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    padding: 10px;
    height: 100%;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    .product-code {
        margin: 0 0 10px 0;
    }
    .product-name{
        // margin-bottom: auto;
        min-height: 48px;
        font-weight: bold;
    }
}

.product-wrapper {
    padding: 8px;
    width: 20%;
}

.product-card:hover {
    transform: translateY(-5px);
}
  
.product-card img {
    width: 100%;
    height: 250px;
    object-fit: contain;
}

.product-card h5 {
    font-size: 14px;
    font-weight: normal;
    margin: 10px 0 5px 0;
    color: #333;
    width: 100%;
}

.product-card p {
    font-size: 16px;
    font-weight: normal;
    margin: 5px 0;
    color: #000;
    width: 100%;
    -webkit-line-clamp: 2; /* Number of lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
}

.product-card h6 {
    font-size: 18px;
    color: #555;
    margin: auto 0 5px 0;
}

.search-product-data {
    .outline-button {
        height: 44px;
    }
    .table-product-search {
        width: 100%;
        padding: 0;
        .ico_float{
            width: 16px;
            height: 16px;
        }
        input{ 
            width: 100%;
            padding: 7px 10px 7px 35px;
            border: 1px solid var(--border);
            height: 44px;
        }
    }
    .ico_float_arrow {
        width: 24px;
        height: 24px;
        position: relative;
        top: 60%;
        transform: translateY(-50%);
        cursor: pointer;
    }
    .up-arrow{
        margin-top: -14px;
    }
    .down-arrow{
        margin-top: 5px;
        margin-left: -23px;
    }
}

.product-url{
    width: 180px !important;
    position: sticky;
    left: 0;
    background-color: white !important;
    z-index: 2;
    white-space: nowrap;
    box-shadow: 4px 0 5px -2px rgba(0, 0, 0, 0.3) !important;
}

.info-icon{
    width: 50px !important;
    left: 0;
    z-index: 9;
    margin-left: 20px;
    white-space: nowrap;
}
.product-link{
    width: 300px !important;
    display: inline-block;
    // box-shadow: 4px 0 5px -2px rgba(0, 0, 0, 0.3) !important;
}

.table-content {
    display: block;
    overflow: auto;
    height: 550px;
}
.table-details{
    min-width: 105% !important;
    th{
        white-space: nowrap;
    }
    // .dates-width{
    //     width: 50px;
    // }
}